// extracted by mini-css-extract-plugin
export var box = "Pride-module--box--2982e";
export var field = "Pride-module--field--39393";
export var itemText = "Pride-module--itemText--8e11f";
export var itemTitle = "Pride-module--itemTitle--67d16";
export var layout = "Pride-module--layout--4d637";
export var project = "Pride-module--project--756a9";
export var secondProject = "Pride-module--secondProject--3b708";
export var subtitle = "Pride-module--subtitle--0cf57";
export var title = "Pride-module--title--1823c";
export var value = "Pride-module--value--0e10e";
export var wrapper = "Pride-module--wrapper--c17a9";