export const anchorLinks = [
  {
    anchor: 'what-we-do',
    alias: '#what-we-do',
    title: 'Что мы делаем',
  },
  {
    anchor: 'pride',
    alias: '#pride',
    title: 'Чем мы гордимся',
  },
  {
    anchor: 'about',
    alias: '#about',
    title: 'О нас',
  },
  {
    anchor: 'contacts',
    alias: '#contacts',
    title: 'Контакты',
  },
];
