import React from 'react';
import cn from 'classnames';

import { Container, Title, Subtitle, Grid, GridItem, Image } from 'landing/ui';

import * as styles from './Pride.module.scss';

export default function Pride({ id = 'pride', theme, title, subtitle, items }) {
  return (
    <>
      <Container id={id}>
        <div className={styles.wrapper}>
          <Title tag="h2" className={cn(styles.title)}>
            {title}
          </Title>
          {subtitle && (
            <Subtitle tag="p" size="none" className={styles.subtitle}>
              {subtitle}
            </Subtitle>
          )}
          {items.map((item, i) => (
            <Grid
              key={i}
              xsm={{ cols: 1 }}
              sm={{ cols: 1 }}
              md={{ cols: 2 }}
              lg={{ cols: 2 }}
              xl={{ cols: 2 }}
              fullHd={{ cols: 2 }}
              customClassName={cn(styles.project, i > 0 && styles.secondProject)}
            >
              <GridItem>
                <ProjectInfo {...item} />
              </GridItem>
              <GridItem key={i}>
                <Image src={item.img.src} alt="background" lazy={false} />
              </GridItem>
            </Grid>
          ))}
        </div>
      </Container>
    </>
  );
}

const ProjectInfo = ({ company, summary, domain, duration, technology, team_size }) => {
  return (
    <>
      <section>
        <section>
          <p className={styles.itemTitle}>{company}</p>
          <p className={styles.itemText}>{summary}</p>
        </section>
        <Grid
          xsm={{ cols: 2 }}
          sm={{ cols: 2 }}
          md={{ cols: 2 }}
          lg={{ cols: 2 }}
          xl={{ cols: 2 }}
          gutterX="10px"
        >
          <GridItem Tag="div">
            <p className={styles.field}>{'Отрасль'}</p>
            <p className={styles.value}>{domain}</p>
          </GridItem>
          <GridItem Tag="div">
            <p className={cn(styles.field)}>{'Команда'}</p>
            <p className={styles.value}>{`${team_size}`}</p>
          </GridItem>
          <GridItem Tag="div">
            <p className={styles.field}>{'Длительность'}</p>
            <p className={styles.value}>{duration}</p>
          </GridItem>
          <GridItem Tag="div">
            <p className={styles.field}>{'Технологии'}</p>
            <p className={styles.value}>{technology}</p>
          </GridItem>
        </Grid>
      </section>
    </>
  );
};
