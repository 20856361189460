import { useEffect, useState, useCallback } from 'react';

export const useIsSsr = () => {
  // we always start off in "SSR mode", to ensure our initial browser render
  // matches the SSR render
  const [isSsr, setIsSsr] = useState(true);

  useEffect(() => {
    // `useEffect` never runs on the server, so we must be on the client if
    // we hit this block
    setIsSsr(false);
  }, []);

  return isSsr;
};

export function useLoadingComponent(ApiCall, ...args) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    ApiCall(...args)
      .then(setData)
      .finally(() => setLoading(false));
  }, []);

  return {
    data,
    loading,
  };
}

/**
 * Управление состоянием компонента Tooltip
 * @param dependencies {array} - зависимости для хука useCallback (для оптимизации)
 * @returns {{isOpen: boolean, showTooltip: function, hideTooltip: function}}
 */
export const useTooltipState = (dependencies = []) => {
  const [isOpen, setIsOpen] = useState(false);

  const showTooltip = useCallback(() => setIsOpen(true), dependencies);
  const hideTooltip = useCallback(() => setIsOpen(false), dependencies);

  return { isOpen, showTooltip, hideTooltip };
};
