import cn from 'classnames';
import React, { useRef } from 'react';
import { Container, Title, Image } from 'landing/ui';
import * as styles from './OurOffices.module.scss';

function OurOffices({
  id = 'about',
  title,
  secondTitle,
  description,
  mainOfficesTitle,
  mainOffices,
  otherOfficesTitle,
  otherOffices,
  img,
}) {
  return (
    <>
      <Container
        id={id}
        background="none"
        className={styles.container}
        padding="Vertical"
        isHaveOverflow={true}
      >
        <div>
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <Title tag="h3" size="h4" className={styles.title}>
                {title}
              </Title>
              {secondTitle && <div className={styles.secondTitle}>{secondTitle}</div>}
              {description && <div className={styles.description}>{description}</div>}
              {mainOfficesTitle && (
                <div className={styles.mainOfficesTitle}>{mainOfficesTitle}</div>
              )}
              {mainOffices && (
                <ul className={styles.mainOfficesList}>
                  {mainOffices.map(city => (
                    <li key={city} className={styles.mainOfficesCity}>
                      {city}
                    </li>
                  ))}
                </ul>
              )}
              {otherOfficesTitle && (
                <div className={styles.mainOfficesTitle}>{otherOfficesTitle}</div>
              )}
              {otherOffices && (
                <ul className={styles.mainOfficesList}>
                  {otherOffices.map(city => (
                    <li key={city} className={styles.mainOfficesCity}>
                      {city}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className={styles.mapWrapper}>
              <div className={cn(styles.mapAbsolute)}>
                <Image src={img.src} alt="Карта офисов" />
              </div>
            </div>
          </div>
        </div>
        <div className={cn(styles.mapAbsolute, styles.mobile)}>
          <Image src={img.src} alt="Карта офисов" />
        </div>
      </Container>
    </>
  );
}

export default OurOffices;
