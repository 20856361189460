import React from 'react';
import cn from 'classnames';

import { Container, Title, Subtitle, Grid, GridItem, Link, Markup, Icon } from 'landing/ui';

import * as styles from './WhatWeDo.module.scss';

export default function WhatWeDo({
  title,
  subtitle,
  items,
  theme = '',
  background = 'darkPrimary',
  subtitleWidth,
  colsXsm = 1,
  colsSm = 2,
  colsMd = 2,
  colsLg = 2,
  colsXl = 2,
  fullHd = 2,
  id = 'what-we-do',
}) {
  return (
    <>
      <Container background={background} id={id}>
        <div className={styles.wrapper}>
          <Title tag="h2" className={cn(styles.title, theme === 'light' && styles.titleLight)}>
            {title}
          </Title>
          {subtitle && (
            <Subtitle
              className={cn(
                styles.subtitle,
                theme === 'light' && styles.subtitleLight,
                subtitleWidth && styles.subtitleWidth,
              )}
              size="md"
            >
              {subtitle}
            </Subtitle>
          )}
          <div className={styles.layout}>
            <Grid
              xsm={{ cols: colsXsm, gutterY: '28px' }}
              sm={{ cols: colsSm }}
              md={{ cols: colsMd }}
              lg={{ cols: colsLg, gutterX: '30px' }}
              xl={{ cols: colsXl, gutterX: '40px' }}
              fullHd={{ cols: fullHd }}
            >
              {items.map((item, i) => (
                <GridItem key={i} customClassName={styles.grid}>
                  <div className={cn(styles.item, theme === 'light' && styles.itemLight)}>
                    <div className={styles.icon}>
                      <Icon name={item.icon} />
                    </div>
                    <Title tag="h3" size="h4" color="white" className={styles.itemTitle}>
                      {item.title}
                    </Title>
                    {item.description && (
                      <Markup className={styles.description} tag="p">
                        {item.description}
                      </Markup>
                    )}
                    {item.list && (
                      <ul className={styles.list}>
                        {item.list.map(listItem => (
                          <li key={listItem} className={styles.checkedItem}>
                            {listItem}
                          </li>
                        ))}
                      </ul>
                    )}
                    <div className={cn(item.link && styles.linkWrapper)}>
                      {item.link && (
                        <Link
                          className={styles.link}
                          href={item.link}
                          target={item.target}
                          state={{ internal: true }}
                        ></Link>
                      )}
                    </div>
                  </div>
                </GridItem>
              ))}
            </Grid>
          </div>
        </div>
      </Container>
    </>
  );
}
