// extracted by mini-css-extract-plugin
export var box = "OurOffices-module--box--701d4";
export var container = "OurOffices-module--container--009a0";
export var content = "OurOffices-module--content--fd21b";
export var description = "OurOffices-module--description--894d7";
export var mainOfficesCity = "OurOffices-module--mainOfficesCity--cd090";
export var mainOfficesList = "OurOffices-module--mainOfficesList--52218";
export var mainOfficesTitle = "OurOffices-module--mainOfficesTitle--de713";
export var mapAbsolute = "OurOffices-module--mapAbsolute--b2da1";
export var mapSectionImage = "OurOffices-module--mapSectionImage--86945";
export var mapWrapper = "OurOffices-module--mapWrapper--d5d18";
export var mobile = "OurOffices-module--mobile--385e0";
export var secondTitle = "OurOffices-module--secondTitle--08580";
export var title = "OurOffices-module--title--e0f6e";
export var wrapper = "OurOffices-module--wrapper--47c26";