import React, { lazy, Suspense, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Container, Title, Loader } from 'landing/ui';
import { useIsSsr } from 'utils/hooks';
import * as styles from './GetEstimationSecondary.module.scss';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import GetEstimationIcon from 'svg/get-estimation.svg';
const CorporateFormSecondary = lazy(() =>
  import('components/Forms/CorporateForm/CorporateFormSecondary'),
);

export function GetEstimationSecondary({ formId = 'get-consultation' }) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const isSsr = useIsSsr();
  return (
    <Container id="getEstimation" background={'darkPrimary'}>
      <div ref={ref} className={styles.wrapper}>
        <div className={styles.iconMobile}>
          <GetEstimationIcon />
        </div>
        <div>
          <Title className={styles.titleDesktop} tag="h2">
            {`Закажите бесплатную \nоценку стоимости разработки ПО`}
          </Title>
          <Title className={styles.titleTablet} tag="h2">
            {`Закажите бесплатную оценку \nстоимости разработки ПО`}
          </Title>
          <Title className={styles.titleMobile} tag="h2">
            {`Закажите бесплатную \nоценку стоимости \nразработки ПО`}
          </Title>
          {inView && !isSsr && (
            <Suspense fallback={<Loader theme="block" />}>
              <CorporateFormSecondary
                id={formId}
                className={styles.form}
                onError={() => setError(true)}
                onSuccess={() => setSuccess(true)}
                submitTitle={'Отправить'}
                captchaInfo={false}
                formName="get-consultation"
              />
            </Suspense>
          )}
        </div>
        <div className={styles.icon}>
          <GetEstimationIcon />
        </div>
      </div>
      <SuccessModal
        isOpen={success}
        closeModal={() => {
          setSuccess(false);
        }}
      />
      <ErrorModal isOpen={error} closeModal={() => setError(false)} />
    </Container>
  );
}
