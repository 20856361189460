import React from 'react';

import { Footer, Head, Header } from 'landing/components';
import Intro from 'components/Intro';
import WhatWeDo from 'components/WhatWeDo';
import Pride from 'components/Pride';
import OurOffices from 'components/OurOffices';
import { GetEstimation, GetEstimationSecondary } from 'components/GetConsultation';
import CookiesPolicy from 'components/CookiesPolicy';

import { anchorLinks } from 'define/anchorLinks';

import data from './data.json';

function Index() {
  return (
    <>
      <Head {...data.head} />
      <Header anchorLinks={anchorLinks} />
      <Intro {...data.intro} />
      <WhatWeDo {...data.whatWeDo} />
      <Pride {...data.pride} />
      <GetEstimationSecondary />
      <OurOffices {...data.ourOffices} />
      <GetEstimation {...data.getEstimation} />
      <Footer {...data.footer} />
      <CookiesPolicy />
    </>
  );
}

export default Index;
